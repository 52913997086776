import { TimesheetShiftData } from "../tp-core-types/Timesheet";
import TimesheetShift from "./TimesheetShift"; 
import "../Timesheet/TimesheetShifts.scss";
import checkmark from "../assets/check_mark.png";
import { useEffect, useState } from "react";

type Props = {
    title: string,
    submissionDate: string|null,
    shifts: Array<TimesheetShiftData>,
    timesheetId: number,
    payPeriodStartDate: Date,
    payPeriodEndDate: Date,
}

const getIsMobile = (): boolean => {
    return window.matchMedia("(max-width: 1250px)").matches;
}

export default function TimesheetShifts(props: Props) {
    const [timesheetId, setTimesheetId] = useState<number>(props.timesheetId);
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
        setTimesheetId(props.timesheetId);
    },[props.timesheetId])

    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);
        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    return (
        <>
        <div id="TimesheetShifts"> 
        {!isMobile && props.submissionDate !== "" && props.submissionDate !== null &&
            <div className="timesheet-header">
                <div className="check-container">
                    <div className="check"></div> 
                </div>
                <div>Submitted {props.submissionDate}</div>
            </div>
        }
       {!isMobile && <div className="timesheet-shifts-title">{props.title}<div className='timesheet-shifts-title-bottom-border'></div></div>}
            {props.shifts.length > 0 && 
            <div className="TimesheetShifts">                
                <div className="timesheet-shifts-data">
                    {props.shifts.map((s: TimesheetShiftData) => { return <TimesheetShift shiftData={s} payPeriodStartDate={props.payPeriodStartDate} payPeriodEndDate={props.payPeriodEndDate} ></TimesheetShift> })}
                </div>
            </div>
            }
            {
                props.shifts.length === 0 &&
                <TimesheetShift shiftData={{shiftId: 0, shiftDate: null, shiftType: "", startTime: null, endTime: null, unit: "", lunch: null, timesheetId: timesheetId}} payPeriodStartDate={props.payPeriodStartDate} payPeriodEndDate={props.payPeriodEndDate}></TimesheetShift>
            }
   </div> </>
    )
}