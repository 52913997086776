import { useContext, useEffect, useState } from "react"
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./TimesheetMobile.scss";
import TimesheetPayPeriods from "./TimesheetPayPeriods";
import { GetHcpActiveContractsWithTimesheetsResult, TimesheetPayPeriod, TimesheetShiftData } from "../tp-core-types/Timesheet";
import displayAsDate from "../utilities/displayAsDate";
import TimesheetShifts from "./TimesheetShifts";
import left_chevron_icon from "../assets/left_chevron_icon.svg";

type Props = {
    handleContractChange: (contractId: number, timesheetId: number|null) => void;
    selectedContract: number | null;
    selectedTimesheet: number | null;
    contractData: GetHcpActiveContractsWithTimesheetsResult[];
    payPeriods: Array<TimesheetPayPeriod> | null;
 }

export default function TimesheetMobile(props: Props){
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [timesheetData, setTimesheetData] = useState<GetHcpActiveContractsWithTimesheetsResult[]>(props.contractData);
    const [payPeriods, setPayPeriods] = useState<Array<TimesheetPayPeriod>| null>(props.payPeriods);
    const userInfoModel = useContext(UserInfoModel)
    const [selectedContractNum, setSelectedContractNum] = useState<number|null>(props.selectedContract);
    const [selectedTimesheetId, setSelectedTimesheetId] = useState<number|null>(props.selectedTimesheet);
    const [payPeriodShiftData, setPayPeriodShiftData] = useState<TimesheetShiftData[]>([]);
    const [selectedTimesheetTitle, setSelectedTimesheetTitle] = useState<string>("");
    const [submissionDate, setSubmittedDate] = useState<string|null>(null);
    const [selectedTimesheet, setSelectedTimesheet] = useState<TimesheetPayPeriod|undefined>()

    useEffect(()=>{
        if(props.selectedContract){
            contractsDropdown_OnChange(props.selectedContract.toString());            
        }
    }, [props.selectedContract]);
    useEffect(()=>{
        if(selectedContractNum){
            props.handleContractChange(selectedContractNum, selectedTimesheetId)
        }
    }, [props.selectedTimesheet]);

    useEffect(()=>{
        setTimesheetData(props.contractData)
    }, [props.contractData]);
    
    useEffect(()=>{
        if(selectedContractNum)
            props.handleContractChange(selectedContractNum, selectedTimesheetId)
    }, [selectedContractNum, selectedTimesheetId])
    
    useEffect(()=>{
        setPayPeriods(props.payPeriods);
    }, [props.payPeriods])


    useEffect(() => {
        getShiftData(); 

        const selectedContract = timesheetData.find(c => c.contractNum === selectedContractNum);
        const selectedTimesheet = selectedContract?.timesheets.find(t => t.timesheetId === selectedTimesheetId);

        if(selectedTimesheet !== undefined){
            setSelectedTimesheetTitle("Timesheet: " + displayAsDate(selectedTimesheet?.startDate) + " - " + displayAsDate(selectedTimesheet?.endDate));
            setSubmittedDate(selectedTimesheet.submittedDate);
            setSelectedTimesheet(selectedTimesheet);
            props.handleContractChange(selectedContractNum!, selectedTimesheetId)
        }
    }, [selectedTimesheetId])


    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        if (Session.getHcpLoginToken() !== ""){
            setShowLoginModalVisibility(false);
        } else {
            setShowLoginModalVisibility(true);
        }
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, []);
    
    
const getShiftData = async() => {
    if(selectedTimesheetId!==null){
        const shiftData = await TPCoreAPI.getTimesheetShiftData(selectedTimesheetId);
        setPayPeriodShiftData(shiftData);
    }
}

    const handleLogin = async() => {
        setShowLoginModalVisibility(false);
    }
    const handleLogout = () => {
        setShowLoginModalVisibility(true);
    }

    function contractsDropdown_OnChange(newContract: string){
        setSelectedContractNum(parseInt(newContract));
    }
 
    return (
        <div id="Timesheet">
            <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
            {selectedTimesheetId != null &&
                <div className="return-to-results-container" onClick={() => {setSelectedTimesheetId(null)}}>
                    <img src={left_chevron_icon} alt="Back to Pay Periods"></img>
                    Pay Periods
                </div>
            }  
            <GenericPageHeader title={selectedTimesheetTitle}></GenericPageHeader>           
            <div className="container-style" data-testid="container-style">
                <div className="left-pane-container">
                    <div className="timesheets-contract-container">

                        {selectedTimesheetId == null &&
                        <>
                            <select data-testid="contracts-ts-display" className="contracts-dropdown" value={selectedContractNum?.toString()} onChange={(e) => contractsDropdown_OnChange(e.target.value)} >
                                {timesheetData.map((contract) => {
                                    return (<option key={contract.contractNum} value={contract.contractNum}>{displayAsDate(contract.startDate) + " - " + displayAsDate(contract.endDate) + " | " + contract.facilityName}</option>)
                                })}
                            </select>
                            <TimesheetPayPeriods payPeriods={payPeriods} handlePayPeriodSelected={(newValue) => { if(newValue) setSelectedTimesheetId(newValue)}}/>  
                        </>
                        }
                        {selectedTimesheetId != null && selectedTimesheet !== undefined &&
                            <div>
                                <TimesheetShifts title={selectedTimesheetTitle} submissionDate={submissionDate} shifts={payPeriodShiftData} timesheetId={selectedTimesheetId!} payPeriodStartDate={new Date(selectedTimesheet.startDate)} payPeriodEndDate={new Date(selectedTimesheet.endDate)}></TimesheetShifts>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
 
}