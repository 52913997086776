import { TimesheetPayPeriod, TimesheetShiftData } from "../tp-core-types/Timesheet"
import "../Timesheet/TimesheetShift.scss";
import displayAsDate from "../utilities/displayAsDate";
import { useEffect, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { TimesheetShiftType } from "../tp-core-types/TimesheetShiftType";
import DatePickerWrapper from "../date-picker-wrapper/DatePickerWrapper";
import displayDateAsTime from "../utilities/displayDateAsTime";
import { ScreenResolutions } from "../common/ScreenResolutions";
import { InputNumber, InputNumberChangeEvent, InputNumberValueChangeEvent } from 'primereact/inputnumber';

type Props = {
    shiftData: TimesheetShiftData,
    payPeriodStartDate: Date,
    payPeriodEndDate: Date
}

export default function TimesheetShift(props: Props){
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [shiftDate, setShiftDate] = useState<Date | null>(props.shiftData.shiftDate);
    const [shiftTypeId, setShiftTypeId] = useState<string>("");
    const [shiftStart, setShiftStart] = useState<Date | null>(props.shiftData.startTime);
    const [shiftEnd, setShiftEnd] = useState<Date | null>(props.shiftData.endTime);
    const [shiftLunch, setShiftLunch] = useState<number | null>(props.shiftData.lunch);
    const [shiftTypes, setShiftTypes] = useState<TimesheetShiftType[]>([]);
    
    const isMobile = window.matchMedia("(max-width: " + ScreenResolutions.MobileResolution.toString() + "px)").matches;
    
    const getShiftTypes = async () => {
        const shiftTypesReturn = await TPCoreAPI.getTimesheetShiftTypes(props.shiftData.timesheetId);
        setShiftTypes(shiftTypesReturn);
    }

    useEffect(()=>{
        if(props.shiftData.timesheetId)
            getShiftTypes();
    },[props.shiftData.timesheetId]);

    useEffect(() => {
        if(props.shiftData.shiftId == 0)
            setIsEditing(true);
    }, [])

    const timeToDate = (event:any) => {
        const hourMin = (event.target.value)
        const hourMinSplit = hourMin.split(':')
        const returnVal = new Date(1900, 0, 1, hourMinSplit[0], hourMinSplit[1]);
        return returnVal;
    }

    return (
        <>
        { !isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShift" data-testid="timesheetShift-display">
                <div className="shift-item">Date: <span className="item-value">{displayAsDate(props.shiftData.shiftDate)}</span></div>
                <div className="shift-item">Shift Type: <span className="item-value">{props.shiftData.shiftType}</span></div>
                <div className="shift-item">Start Time: <span className="item-value">{displayDateAsTime(props.shiftData.startTime)}</span></div>
                <div className="shift-item">End Time: <span className="item-value">{displayDateAsTime(props.shiftData.endTime)}</span></div>
                <div className="shift-item">Unit: <span className="item-value">{props.shiftData.unit}</span></div>
                <div className="shift-item">Lunch: <span className="item-value">{props.shiftData.lunch} minutes</span></div>
            </div>
        </div>
        }
        {!isMobile && isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit">
                <div className="shift-item">
                    <label htmlFor="shift-date">Date</label>
                    <DatePickerWrapper
                        inputId="shift-date" 
                        showButtonBar={true}
                        onChange={(event)=>{event.value && setShiftDate(event.value)}}
                        onClear={()=>{setShiftDate(null)}}
                        value={shiftDate}
                        minDate={props.payPeriodStartDate} 
                        maxDate={props.payPeriodEndDate}
                    />
                </div>
                <div className="shift-item">
                    <label htmlFor="shift-type">Shift Type</label>
                    <select id="shift-type" className="item-value" value={shiftTypeId}>
                        {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                    </select>
                </div>
                <div className="shift-item">
                    <label htmlFor="shift-start">Shift Start Time</label>
                    <input id="shift-start" type='time' className="item-value" onChange={event => setShiftStart(timeToDate(event))} value={displayDateAsTime(shiftStart)}></input>
                </div>
                <div className="shift-item">
                    <label htmlFor="shift-end">Shift End Time</label>
                    <input id="shift-end" type='time' className="item-value" onChange={event => setShiftEnd(timeToDate(event))} value={displayDateAsTime(shiftEnd)}></input>
                </div>
                <div className="shift-item">
                    <label htmlFor="shift-lunch">Lunch</label>
                    <InputNumber id="shift-lunch" data-testid="shift-lunch" value={shiftLunch} min={0} max={100} onValueChange={(e: InputNumberValueChangeEvent) => setShiftLunch(e.value === undefined ? null : e.value)} />
                </div>
            </div>
            <div>
                <button className="shift-delete-button">Delete Entry</button>
                <button className="shift-save-button">Save Time</button>
            </div>
        </div>
        } 
        {isMobile && isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit"> 
                <div className="edit-shift-item">
                    <label htmlFor="shift-date">Date</label>
                    <DatePickerWrapper
                        inputId="shift-date" 
                        showButtonBar={true}
                        onChange={(event)=>{event.value && setShiftDate(event.value)}}
                        onClear={()=>{setShiftDate(null)}}
                        value={shiftDate} 
                    />
                </div>
                <div className="edit-shift-item"><label htmlFor="shift-type">Shift Type* </label>
                    <select id="edit-shift-item" className="item-value" value={shiftTypeId}>
                        {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                    </select></div>
                <div className="edit-shift-item">
                    <label htmlFor="shift-start">Start Time*</label>
                    <input id="shift-start" type='time' className="item-value" onChange={event => setShiftStart(timeToDate(event))} value={displayDateAsTime(shiftStart)}></input>
                </div>
                <div className="edit-shift-item">
                    <label htmlFor="shift-end">End Time*</label>
                    <input id="shift-end" type='time' className="item-value" onChange={event => setShiftEnd(timeToDate(event))} value={displayDateAsTime(shiftEnd)}></input>
                </div>
                <div className="edit-shift-item">
                    <label htmlFor="shift-lunch">Lunch*</label>
                    <input id="shift-lunch" type='text' className="item-value" onChange={event => setShiftLunch(parseInt(event.target.value))} value={shiftLunch?.toString()}></input>
                </div>
            </div>
            <div>
                <button className="shift-delete-button">Delete Entry</button>
                <button className="shift-save-button">Save Entry</button>
            </div>
        </div>
        }
        </>
    )
}