import { useContext, useEffect, useState } from "react"
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./TimesheetDesktop.scss";
import TimesheetPayPeriods from "./TimesheetPayPeriods";
import { GetHcpActiveContractsWithTimesheetsResult, TimesheetPayPeriod, TimesheetShiftData } from "../tp-core-types/Timesheet";
import displayAsDate from "../utilities/displayAsDate";
import TimesheetShifts from "./TimesheetShifts";

 type Props = {
    handleContractChange: (contractId: number, timesheetId: number | null) => void;
    selectedContract: number | null;
    selectedTimesheet: number | null;
    contractData: GetHcpActiveContractsWithTimesheetsResult[];
    payPeriods: Array<TimesheetPayPeriod> | null;
 }
export default function TimesheetDesktop(props: Props){
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [timesheetData, setTimesheetData] = useState<GetHcpActiveContractsWithTimesheetsResult[]>(props.contractData);
    const [payPeriods, setPayPeriods] = useState<Array<TimesheetPayPeriod>| null>(props.payPeriods);
    const userInfoModel = useContext(UserInfoModel)  
    const [selectedContractNum, setSelectContractNum]= useState <number|null> (props.selectedContract);
    const [selectedTimesheetId, setSelectedTimesheetId] = useState<number|null>(props.selectedTimesheet);
    const [payPeriodShiftData, setPayPeriodShiftData] = useState<TimesheetShiftData[]>([]);
    const [selectedShiftTitle, setSelectedShiftTitle] = useState<string>("");
    const [submissionDate, setSubmissionDate] = useState<string|null>(null);
    const [selectedTimesheet, setSelectedTimesheet] = useState<TimesheetPayPeriod|undefined>()

useEffect(()=>{
    setSelectedTimesheetId(props.selectedTimesheet);
}, [props.selectedTimesheet]);
useEffect(() => {
    if(props.selectedContract)
        setShowContract(props.selectedContract);
}, [props.selectedContract])
useEffect(()=>{
    setPayPeriods(props.payPeriods)    
}, [props.payPeriods])

useEffect(()=>{
    if(selectedContractNum)
        props.handleContractChange(selectedContractNum, null)
}, [selectedContractNum])

useEffect(() => {
    if(selectedTimesheetId != null) {
        getShiftData();
        const selectedTimesheet = timesheetData.find(c => c.contractNum === selectedContractNum)?.timesheets.find(t => t.timesheetId === selectedTimesheetId);
        if(selectedTimesheet !== undefined){
            setSelectedShiftTitle(displayAsDate(selectedTimesheet?.startDate) + " - " + displayAsDate(selectedTimesheet?.endDate));
            setSubmissionDate(displayAsDate(selectedTimesheet?.submittedDate ?? ""));
            setSelectedTimesheet(selectedTimesheet);
        }
    }
    else{
        setPayPeriodShiftData([]);
        setSelectedShiftTitle("");
        setSubmissionDate(null);
    }
    props.handleContractChange(selectedContractNum!, selectedTimesheetId)
}, [selectedTimesheetId])

useEffect(() => {
    userInfoModel.subscribeForLogout(handleLogout)
    if (Session.getHcpLoginToken() !== ""){
        setShowLoginModalVisibility(false);
    } else {
        setShowLoginModalVisibility(true);
    }
    
    return function cleanup() {
        userInfoModel.unsubscribeForLogout(handleLogout)
    }
}, []);

const getShiftData = async() => {
    if(selectedTimesheetId!==null){
        const shiftData = await TPCoreAPI.getTimesheetShiftData(selectedTimesheetId);
        setPayPeriodShiftData(shiftData);
    }
}

const handleLogin = async() => {
    setShowLoginModalVisibility(false);
}
const handleLogout = () => {
    setShowLoginModalVisibility(true);
}

function setShowContract( contractNum: number | null){
    
    if(timesheetData == null) return;
    setSelectContractNum(contractNum);
} 

function handleTimesheetSelected(newValue: number) {
    if (newValue) 
        setSelectedTimesheetId(newValue);
}

return (

    <div id="Timesheet">
        <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
        <GenericPageHeader title={`Timesheet`}></GenericPageHeader>           
        <div className="container-timesheets" data-testid="container-timesheets" >           
            <div className="container-tab" data-testid="container-tab">
                {timesheetData.map((contract) => {
                return (
                    <div className="container-content-list"
                      key={contract.contractNum}
                      {...contract.contractNum==selectedContractNum && {className:"container-content-list-selected container-content-list"}}


                    onClick={() => {
                        setShowContract(contract.contractNum);  
                        
                    }}> 
                        <div className="container-content-list-facility"  
                            data-testid={contract.contractNum}>
                            {contract.facilityName + "," }  
                        </div> 
                        <div >
                            {contract.city + ", " + contract.state + " | " + displayAsDate(contract.startDate) + " - " + displayAsDate(contract.endDate)} 
                        </div> 
                    </div>
                       
                )})}    
            </div>
            <div className="content-container"> 
                <div className="timesheet-container">   
                    <div className="timesheet-container-left">
                        <div className="timesheet-container-header">Pay Periods</div>
                        <TimesheetPayPeriods payPeriods={payPeriods} 
                        handlePayPeriodSelected={handleTimesheetSelected}/>  
                    </div>     
                    {selectedTimesheetId !== null && selectedTimesheet !==undefined &&
                        <div className="timesheet-container-right">
                            <TimesheetShifts timesheetId={selectedTimesheetId ?? 0} submissionDate={submissionDate} title={selectedShiftTitle} shifts={payPeriodShiftData} payPeriodStartDate={new Date(selectedTimesheet.startDate)} payPeriodEndDate={new Date(selectedTimesheet.endDate)}></TimesheetShifts>
                        </div>
                    }
                </div> 
            </div>            
            
        </div> 
                    
    </div>
)
}
