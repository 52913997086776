export type GetHcpActiveContractsWithTimesheetsResult = {
    contractNum: number,
    startDate: string,
    endDate: string,
    facilityName: string,
    city: string,
    state: string,
    timesheets: Array<TimesheetPayPeriod>
}

export type TimesheetPayPeriod = {
    timesheetId: number,
    startDate: string,
    endDate: string,
    isLocked: boolean,
    submittedDate: string | null
}

export class TimesheetShiftData {
    public shiftId: number;
    public shiftDate: Date | null;
    public shiftType: string;
    public startTime: Date | null;
    public endTime: Date | null;
    public unit: string;
    public lunch: number | null;
    public timesheetId: number;

    constructor(json: any) {
        this.shiftId = json.shiftId;
        this.shiftDate = json.shiftDate ? new Date(json.shiftDate) : null;
        this.shiftType = json.shiftType;
        this.startTime = json.startTime ? new Date(json.startTime) : null;
        this.endTime = json.endTime ? new Date(json.endTime) : null;
        this.unit = json.unit;
        this.lunch = json.lunch;
        this.timesheetId = json.timesheetId;
    }
}
